





























































































































































































































































































import { PropType } from 'vue';
import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  ref,
  Ref,
} from '@vue/composition-api';

import VfEyebrow from './Atom.Eyebrow.vue';
import VfProductBadge from './Molecule.ProductBadge.vue';
import { OrderStatus } from '@vf/shared/src/utils/helpers';
import { focus, swipe } from '@vf/shared/src/utils/directives';
import { isSafari } from '@vf/shared/src/utils/helpers';

interface BadgeImgMap {
  large: string;
  medium: string;
  small: string;
}

interface Badge {
  src?: BadgeImgMap;
  alt: string;
}

interface AddToCartCtaConfig {
  label: string;
  icon: string;
  disabled: boolean;
}

export default defineComponent({
  name: 'VfProductCard',
  components: {
    VfProductBadge,
    VfEyebrow,
  },
  directives: { focus, swipe },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    badges: {
      type: Array as PropType<Array<Badge>>,
      default: () => [],
    },
    imageWidth: {
      type: Number,
      default: 326,
    },
    imageHeight: {
      type: Number,
      default: 326,
    },
    rating: {
      type: [Object, Array],
      default: () => ({}),
    },
    ratingLink: {
      type: String,
      default: '',
    },
    showAddToCartButton: {
      type: Boolean,
      default: true,
    },
    showAddToFavourites: {
      type: Boolean,
      default: true,
    },
    currency: {
      type: String,
      default: '',
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    isOnWishlist: {
      type: Boolean,
      default: false,
    },
    wishlistIcon: {
      type: [String, Boolean, Array],
      default: 'heart',
    },
    addToWishlistLoading: {
      type: Boolean,
      default: false,
    },
    isOnWishlistIcon: {
      type: [String, Array],
      default: 'heart__fill',
    },
    title: {
      type: String,
      default: '',
    },
    eyebrow: {
      type: [Object, Array],
      default: () => ({}),
    },
    link: {
      type: String,
      default: '',
    },
    seeMoreLabel: {
      type: String,
      default: '',
    },
    image: {
      type: [Array, Object, String],
      default: '',
    },
    specialPricePrefix: {
      type: String,
      default: '',
    },
    regularPricePrefix: {
      type: String,
      default: '',
    },
    addWishListAriaLabel: {
      type: String,
      default: '',
    },
    removeWishListAriaLabel: {
      type: String,
      default: '',
    },
    reviewLabel: {
      type: String,
      default: '',
    },
    reviewLink: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    searchState: {
      type: Object,
      default: null,
    },
    isProductGrid: {
      type: Boolean,
      default: false,
    },
    colors: {
      type: Object,
      default: () => ({}),
    },
    productId: {
      type: String,
      default: '',
    },
    parsedProductId: {
      type: String,
      default: '',
    },
    showQuickShop: {
      type: Boolean,
      default: true,
    },
    showQuickShopCta: {
      type: Boolean,
      default: true,
    },
    isImageSwiper: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    swiperSettings: {
      type: Object,
      default: () => ({}),
    },
    showSwiperIcons: {
      type: Boolean,
      default: false,
    },
    variantId: {
      type: String,
      default: '',
    },
    pictureScreenLarge: Number,
    pictureScreenMedium: Number,
    addToCartCta: {
      type: Object as PropType<AddToCartCtaConfig>,
      default: () => null,
    },
    showAddToCartCta: Boolean,
    isCustoms: Boolean,
    isSoldOut: {
      type: Boolean,
      default: false,
    },
    photoRatioBadge: {
      type: String,
      default: '',
    },
    lastFocusedElement: {
      type: String,
    },
  },
  setup(props, { root, emit }) {
    const isVansPlpRedesignEnabled = inject('isVansPlpRedesignEnabled');
    const parsedLink: Ref<string> = ref('');
    const swiped = ref(false);
    const { isMobileDevice } = root.$viewport;

    const showReviewLink: ComputedRef<boolean> = computed(() => {
      const showReview = [OrderStatus.shipped].includes(
        props.status as OrderStatus
      );
      return !!(showReview && props.reviewLink && props.reviewLabel);
    });

    const isShowQuickShopTile = computed<boolean>(() => {
      return (
        props.showQuickShop &&
        !!root.$themeConfig?.productCard?.showQuickShopTile
      );
    });

    const productCardModifier = computed<string>(() => {
      return (
        root.$themeConfig?.productCard?.productCardModifier || 'vf-product-card'
      );
    });

    const parseLink = (link: string): string => {
      if (root.$themeConfig?.productCard?.updateColorOnBack) {
        return `${link}${link.includes('?') ? '' : '?'}&tile=${
          props.variantId
        }`;
      }

      return link;
    };

    const changeSelectedSwatch = (data): void => {
      parsedLink.value = parseLink(data.link);
      emit('change-selected-swatch', data.label);
    };

    const handlePictureWrapperSwipe = () => {
      swiped.value = !swiped.value;
    };

    parsedLink.value = parseLink(props.link);

    const handleCtaClick = () => {
      if (props.addToCartCta.disabled) return;
      emit('add-to-cart');
    };

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      changeSelectedSwatch,
      isShowQuickShopTile,
      parsedLink,
      productCardModifier,
      showReviewLink,
      isMobileDevice,
      swiped,
      handlePictureWrapperSwipe,
      handleCtaClick,
      isCoreRedesignEnabled,
      isVansPlpRedesignEnabled,
    };
  },
  data() {
    return {
      isQuickShopOpened: false,
      quickShopImage: '',
    };
  },
  computed: {
    ariaLabel() {
      return this.isOnWishlist
        ? this.removeWishListAriaLabel
        : this.addWishListAriaLabel;
    },
    currentWishlistIcon() {
      return this.isOnWishlist ? this.isOnWishlistIcon : this.wishlistIcon;
    },
    wishlistIconClasses() {
      const defaultClass = 'vf-button--pure vf-product-card__wishlist-icon';
      return `${defaultClass} ${
        this.isOnWishlist
          ? 'vf-product-card--on-wishlist product-card__wishlist-button--on-wishlist'
          : ''
      }`;
    },
    getProductEyebrow() {
      return this.eyebrow?.length > 0 ? this.eyebrow[0] : '';
    },
    renderRating() {
      return (
        !this.isCustoms &&
        this.rating &&
        !!Object.keys(this.rating).length &&
        this.ratingLink
      );
    },
    hasEyebrow() {
      return this.eyebrow && !!Object.keys(this.eyebrow).length;
    },
    hasSpecialPrice() {
      return this.specialPrice && this.regularPrice !== this.specialPrice;
    },
    badgePosition() {
      const positionModifier =
        this.$themeConfig.productCard?.badgePosition ?? 'left';
      return `product-card__badges--${positionModifier}`;
    },
    isArrayImages() {
      return Array.isArray(this.image);
    },
    arrayImages() {
      const imageArray = this.isArrayImages ? this.image : [this.image];
      if (this.quickShopImage) {
        imageArray[0] = this.quickShopImage;
      }
      return imageArray.map((image) =>
        typeof image === 'string' ? { small: image } : image
      );
    },
    showQuickshopButton() {
      return (
        this.showAddToCartButton &&
        !this.$viewport?.isMobileDevice &&
        this.showQuickShopCta
      );
    },
    productConfig() {
      return {
        id: this.parsedProductId,
        link: this.parsedLink,
        title: this.title,
        regularPrice: this.$formatPrice(this.regularPrice, this.currency),
        specialPrice: this.hasSpecialPrice
          ? this.$formatPrice(this.specialPrice, this.currency)
          : '',
        colors: this.colors.options,
      };
    },
    actionsConfig() {
      return {
        changeSelectedSwatch: this.changeSelectedSwatch,
        changeQuickshopImage: this.changeQuickshopImage,
        toggleQuickshop: this.toggleQuickshop,
      };
    },
  },

  methods: {
    isSwiperItemLazy(key) {
      return isSafari()
        ? key > 1 && key < this.arrayImages.length - 1
        : this.lazy || key > 0;
    },
    toggleIsOnWishlist() {
      this.$emit('click-wishlist', !this.isOnWishlist);
    },
    toggleQuickshop(flag) {
      this.isQuickShopOpened = flag;
    },
    changeQuickshopImage(colorCode: string) {
      const colorName = this.colors.options.find(
        ({ value }) => value === colorCode
      ).name;
      if (this.isImageSwiper) {
        this.$emit('change-quickshop-image', {
          colorCode,
          colorName,
          productId: this.productId,
          productName: this.title,
        });
      } else {
        let shotType = '';
        if (this.$root.$themeConfig?.productCard?.showImagesWithShotType) {
          shotType = `_${colorCode || ''}_hero`;
        }
        this.quickShopImage = this.$root.$mediaUrlGenerator({
          colorCode,
          colorName,
          productName: this.title,
          pid: this.parsedProductId,
          shotType,
        });
      }
    },
    triggerMouseOver() {
      if (!this.isImageSwiper) return;
      this.$emit('load-plp-gallery', {
        productId: this.productId,
        productName: this.title,
        colorCode: this.colors.selected,
        colorName: this.colors.options.find(
          (color) => color.value === this.colors.selected
        )?.name,
      });
    },
  },
});
