import type {
  Cart,
  CartLineItemCustomerNotification,
} from '@vf/api-client/src/types';
import type { EAPIError, RecursivePartial } from '@vf/api-contract';
import type { ErrorDetailObject } from '../../../useCart';
import type { PutOrderObject } from '@vf/api/src/types/Checkout';

import { ApplePayContext } from '../../../useApplePay/types';

import { storeToRefs } from 'pinia';
import { computed } from '@nuxtjs/composition-api';

import { apiClientFactory, PaymentMethodCode } from '@vf/api-client';
import { stripPhoneAndPrependCountryCallingCode } from '@vf/shared/src/utils/helpers';
import { prepareTemporaryBasket } from '../../../utils/query';
import ss from '../../../utils/sessionStorage';
import merge from '../../../utils/merge';

import { ROUTES } from '../../../utils/routes';
import { useApplePayPdpCart, useCart } from '../../../useCart';

import { errorMessages } from '../../../utils/errorMessages';
import { useI18n } from '../../../useI18n';
import { usePayPal } from '../../../usePayPal';
import { useReCaptcha } from '../../../useReCaptcha';
import { useUtilities } from '../../../useUtilities';
import useCyberSource from '../../../useCyberSource';
import useKlarna from '../../../useKlarna';

import useSignInToStore from '../../../useSignInToStore';
import { useOrderStore } from '../../../store/order';
import { useUserStore } from '../../../store/user';
import { useAddressValidationServiceStore } from '../../../store/addressValidationService';
import { useCartStore } from '../../../store/cartStore';
import { useCheckoutStore } from '../../../store/checkoutStore';
import { useCartNotificationsStore } from '../../../store/cartNotificationsStore';
import { useCyberSourceStore } from '../../../store/cyberSourceStore';
import { useAddressesStore } from '../../../store/addresses';
import { usePaymentProvider } from '../../../usePaymentProvider';
import { useCardStore } from '../../../store/card';
import { useSuccessfulOrder } from '../../../useSuccessfulOrder';
import { useFeatureFlagsStore } from '../../../store/featureFlags';
import { usePaymentStore } from '../../../store/payment';

export const useOrders = (instance, dependency?, contextKey?) => {
  const { shouldStoreBillingAddress, storeBillingAddress } = dependency || {};
  const { setAVSSkip } = useAddressValidationServiceStore();
  const addressesStore = useAddressesStore(instance);
  const cartStore = useCartStore();
  const {
    appendCartMessages,
    resetBopisTransitionNotifications,
  } = useCartNotificationsStore();
  const checkoutStore = useCheckoutStore();
  const cyberSourceStore = useCyberSourceStore(instance);
  const orderStore = useOrderStore();
  const {
    isPlaceOrderAttemptInProgress,
    isPlaceOrderButtonDisabled,
    loading,
    applePayOrderToken,
    paymentMethod,
  } = storeToRefs(orderStore);
  const userStore = useUserStore(instance);
  const cardStore = useCardStore();
  const paymentStore = usePaymentStore(instance);
  const { loggedIn } = storeToRefs(userStore);

  const { clearShippingAddress } = addressesStore;
  const { billingAddress } = storeToRefs(addressesStore);

  const { getStateCode } = useUtilities(instance);
  const {
    placeOrder: placeOrderAPI,
    patchOrder: patchOrderAPI,
  } = apiClientFactory(instance);
  const { inStoreSalesInfo, hasStoreCookie } = useSignInToStore(instance);
  const { displayErrorMessages, getErrorDetails } = errorMessages(instance);
  const { getPayPalOrderPayload, payPalCheckoutFlowInProgress } = usePayPal(
    instance
  );
  const { sessionID } = useCyberSource(instance);
  const { localePath } = useI18n(instance);

  const { authorizeKlarnaPayment } = useKlarna(instance);

  const {
    loadCart,
    resetCart,
    createNewCart,
    cart,
    cartId,
    hasShippingItems,
    updateCartFlashesFromError,
    updateCustomerNotifications,
    setMissingPhoneErrorDetail,
  } =
    contextKey === ApplePayContext.PDP
      ? useApplePayPdpCart(instance)
      : useCart(instance);

  const sendOrder = async (
    paymentData: {
      payment_instrument_id?: string;
      cardType?: string;
      captureContextKey?: string;
      microformToken?: string;
      sessionID?: string;
      c_applePayToken?: string;
    },
    extraPayload: {
      paymentMethod: string;
      ignoreAddress?: boolean;
      isPaypalExpress?: boolean;
    },
    { isTemporary } = { isTemporary: false }
  ): Promise<{ success: boolean; errors?: EAPIError[] }> => {
    const {
      paymentMethod,
      ignoreAddress = false,
      isPaypalExpress = false,
    } = extraPayload;
    isPlaceOrderButtonDisabled.value = true;
    try {
      loading.value = true;
      const payload: any = {
        cartId: cartId.value,
        paymentMethod: {
          code: paymentMethod,
          additionalData: {
            ...paymentData,
            sessionID:
              orderStore.forterToken ||
              paymentData.sessionID ||
              sessionID.value,
          },
        },
      };

      if (!ignoreAddress) {
        const country =
          billingAddress.value.country || billingAddress.value.countryCode;
        payload.billingAddress = {
          addressId: billingAddress.value.id || '',
          firstName: billingAddress.value.firstName,
          lastName: billingAddress.value.lastName,
          email: billingAddress.value.email,
          country,
          addressLine1: billingAddress.value.addressLine1,
          addressLine2: billingAddress.value.addressLine2 || '',
          postalCode: billingAddress.value.postalCode,
          city: billingAddress.value.city,
          province: await getStateCode(
            billingAddress.value.province || billingAddress.value.stateCode,
            country
          ),
          phone: stripPhoneAndPrependCountryCallingCode(
            billingAddress.value.phone,
            country
          ),
        };
      }

      const query = [];

      if (paymentMethod === PaymentMethodCode.CREDIT_CARD) {
        const { executeRecaptcha } = useReCaptcha(instance);

        const recaptchaToken = await executeRecaptcha('placeOrder');

        if (recaptchaToken) {
          payload.recaptcha_response = recaptchaToken;
        }

        payload.paymentMethod.paymentCard = {
          cardType:
            payload.paymentMethod?.additionalData?.cardType?.toUpperCase() ||
            'VISA',
        };
      }

      if (isPaypalExpress) {
        query.push(`context=${PaymentMethodCode.PAYPAL_EXPRESS}`);
      }

      if (isTemporary) {
        query.push(prepareTemporaryBasket());
      }

      if (hasStoreCookie.value) {
        payload.inStoreSalesInfo = inStoreSalesInfo.value;
      }

      if (!ignoreAddress && shouldStoreBillingAddress(payload.billingAddress)) {
        await storeBillingAddress(payload.billingAddress);
      }

      const data = await placeOrderAPI(payload, {
        query: query.join('&'),
      });

      if (data?.data?.orderNumber) {
        ss.removeItem('AVSoldAddress');
        ss.removeItem('AVSdone');

        checkoutStore.$patch((state) => {
          state.order = data.data;
          state.previousCart = cart.value;
        });

        orderStore.resetOrderStore();
        cartStore.resetCartStore();
        // Clear cart after order
        resetCart({ suppressLocalStorageOverride: isTemporary });
        setAVSSkip(false);
        clearShippingAddress();
        resetBopisTransitionNotifications();

        if (loggedIn.value && !isTemporary) {
          await createNewCart({
            isBackgroundRequest: false,
            isTemporary: false,
          });
        }

        instance.$router.push(localePath(ROUTES.CHECKOUT_ORDER_STATUS()));
        loading.value = false;
        return { success: true };
      } else {
        resetPlaceOrderButton();
        loading.value = false;
        return { success: false };
      }
    } catch (e) {
      console.log(e);
      const errorDetails = await processOrderErrorMessages(
        e.response.data,
        isTemporary
      );
      return { success: false, errors: errorDetails };
    }
  };

  const resetPlaceOrderButton = (): void => {
    isPlaceOrderButtonDisabled.value = false;
    isPlaceOrderAttemptInProgress.value = false;
  };

  const processOrderErrorMessages = async (
    responseData,
    isTemporary: boolean
  ): Promise<EAPIError[]> => {
    // for order placement attempts with shipping address missing phone number
    // (i.e. for accounts migrated from ECOM-1.0) action is rejected and it is
    // required to redirect the customer back to shipping page to provide it,
    // see: GLOBAL15-22061
    const errorDetails = responseData?.errorDetails ?? [];
    updateCartFlashesFromError(errorDetails);
    const missingPhoneErrorId = 'SHP410';
    displayErrorMessages({
      response: {
        data: {
          errorDetails: errorDetails.filter(
            (errorObject: EAPIError) =>
              errorObject.errorId !== missingPhoneErrorId
          ),
        },
      },
    });
    const missingPhoneErrorDetail = getErrorDetails(errorDetails).find(
      (errorObject: ErrorDetailObject) => {
        return errorObject.errorMessageId === missingPhoneErrorId;
      }
    );
    setMissingPhoneErrorDetail(missingPhoneErrorDetail);

    // in case when the order gets rejected with a change of delivery method
    // set the messages for customer
    const mappedCustomerNotifications = mapPlaceOrderErrorToCustomerNotifications(
      errorDetails
    );
    updateCustomerNotifications(mappedCustomerNotifications);

    if (errorDetails[0]?.errorId === 'ORD430') {
      appendCartMessages({
        flash: errorDetails[0].message?.map((flash) => ({
          code: flash.type,
          path: flash.path,
          details: flash.details,
        })),
      } as Cart);
    }
    const toSthTransitionTriggered = mappedCustomerNotifications.some(
      (notification: CartLineItemCustomerNotification) =>
        notification?.type?.endsWith('ToSthTransition')
    );
    if (!isTemporary) {
      await loadCart({
        isBackgroundRequest: false,
        isTemporary: false,
        inventorySupplyCheck: true,
      });

      if (
        (hasShippingItems.value && toSthTransitionTriggered) ||
        missingPhoneErrorDetail
      ) {
        instance.$router.push(localePath(ROUTES.CHECKOUT_SHIPPING()));
      }
    }
    resetPlaceOrderButton();
    loading.value = false;
    return errorDetails;
  };

  // this is only for applepay (GLOBAL15-86830) and paypal (GLOBAL15-87730) to trigger old 'sendOrder' method.
  const placeOrder = async (
    { isTemporary } = {
      isTemporary: false,
    }
  ): Promise<{
    success: boolean;
    errors?: EAPIError[];
  } | void> => {
    switch (paymentMethod.value) {
      case PaymentMethodCode.PAYPAL:
      case PaymentMethodCode.PAYPAL_EXPRESS:
        return await sendOrder(
          Object.assign(
            getPayPalOrderPayload(
              payPalCheckoutFlowInProgress.value &&
                paymentMethod.value !== PaymentMethodCode.PAYPAL_EXPRESS
            ),
            {
              payment_method_id: 'PAYPAL',
            }
          ),
          {
            paymentMethod: 'PAYPAL',
            ignoreAddress: true,
            isPaypalExpress:
              paymentMethod.value === PaymentMethodCode.PAYPAL_EXPRESS,
          }
        );
      case PaymentMethodCode.APPLEPAY:
        return await sendOrder(
          { c_applePayToken: applePayOrderToken.value },
          { paymentMethod: PaymentMethodCode.APPLEPAY },
          { isTemporary }
        );
      default:
        resetPlaceOrderButton();
        console.error(`Payment not supported`);
    }
  };

  const isZeroOrder = computed(
    () =>
      cart.value.totals.total <= 0 &&
      typeof cart.value.totals.remainingToPay === 'undefined'
  );

  const getOrderBillingAddressPayload = async () => {
    const address = cartStore.billingAddress;
    const state = address.province ?? address.stateCode;
    const country = address.country ?? address.countryCode;
    return {
      addressId: address.addressId ?? address.id ?? '', // "addressId" is coming from cart API, "id" is coming from consumer API
      firstName: address.firstName,
      lastName: address.lastName,
      email: address.email,
      country,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2 ?? '',
      postalCode: address.postalCode,
      city: address.city,
      province: await getStateCode(state, country),
      phone: stripPhoneAndPrependCountryCallingCode(address.phone, country),
    };
  };

  const requestOrder = async (
    payload: RecursivePartial<PutOrderObject>,
    options: {
      isTemporary?: boolean; // Determines Apple Pay session from Cart Page (not used yet GLOBAL15-86830)
      query?: string[];
    } = {
      isTemporary: false,
      query: [],
    }
  ): Promise<{
    success: boolean;
    errors?: EAPIError[];
  }> => {
    if (isPlaceOrderAttemptInProgress.value) {
      return;
    }

    isPlaceOrderAttemptInProgress.value = true;
    isPlaceOrderButtonDisabled.value = true;

    const { isTemporary, query = [] } = options;
    try {
      const orderPayload: PutOrderObject = merge(
        {
          cartId: cartId.value,
          paymentMethod: {
            code: paymentMethod.value,
            additionalData: {
              sessionID: orderStore.forterToken,
            },
          },
          billingAddress:
            payload.billingAddress || (await getOrderBillingAddressPayload()),
          ...(hasStoreCookie.value && {
            inStoreSalesInfo: inStoreSalesInfo.value,
          }),
        },
        payload
      );

      if (isTemporary) {
        query.push(prepareTemporaryBasket());
      }

      const { data } = await placeOrderAPI(orderPayload, {
        query: query.join('&'),
      });

      const notAuthorized = data.flash?.find(
        ({ code }) => code === 'PaymentNotAuthorized'
      );

      if (data.orderNumber) {
        // had to pick it from the conditional below to have
        // this data when we need to patch order in case of 3DS challenge
        checkoutStore.$patch((state) => {
          state.order = data;
          state.previousCart = cart.value;
        });
      }

      if (useFeatureFlagsStore().enable3ds && notAuthorized) {
        orderStore.challenge3ds = notAuthorized.details?.action || '';
        return;
      }

      if (data.orderNumber) {
        useSuccessfulOrder(instance, contextKey, isTemporary);
      }
    } catch (error) {
      instance.$log.error(
        '[@composables/useCheckout/composables/useOrders::requestOrder]',
        error
      );
      const errorDetails = await processOrderErrorMessages(
        error.response.data,
        isTemporary
      );
      return { success: false, errors: errorDetails };
    } finally {
      isPlaceOrderAttemptInProgress.value = false;
      isPlaceOrderButtonDisabled.value = false;
    }
  };

  const getCreditCardPayload = () => {
    const orderAdditionalData =
      checkoutStore.order?.paymentMethod?.[0].additionalData;
    const cardType =
      orderAdditionalData?.card_type ||
      cardStore.card?.cardType ||
      cardStore.cardType;
    const endDigits = (
      orderAdditionalData?.masked_number ||
      cardStore.card?.maskedNumber ||
      cardStore.cardNumber
    ).slice(-4);

    const paymentInstrumentId =
      orderAdditionalData?.payment_instrument_id ||
      cardStore.card?.paymentInstrumentId;

    return {
      code: paymentMethod.value,
      paymentCard: {
        cardType,
        ...(paymentStore.provider !== 'CYBERSOURCE' && { endDigits }),
      },
      additionalData: {
        ...(paymentInstrumentId
          ? {
              payment_instrument_id: paymentInstrumentId,
            }
          : usePaymentProvider(instance).getAdditionalData()),
        cardType,
        sessionID: orderStore.forterToken || cyberSourceStore.sessionID,
        saveCC: cardStore.saveCreditCard,
      },
      ...(orderStore.bin?.binValue && {
        bin: orderStore.bin.binValue,
        type: orderStore.bin.type,
      }),
    };
  };

  const patchOrder = async (threeDS2Result) => {
    const { executeRecaptcha } = useReCaptcha(instance);
    const recaptchaToken = await executeRecaptcha('patchOrder');

    const threeDSType = checkoutStore.order.flash?.[0]?.message;

    const payload = getCreditCardPayload();

    const { data } = await patchOrderAPI(checkoutStore.order.orderNumber, {
      recaptcha_response: recaptchaToken,
      paymentMethod: {
        ...payload,
        additionalData: [
          {
            ...payload.additionalData,
            threeDS2Result:
              threeDSType === 'redirect'
                ? JSON.stringify({
                    details: { redirectResult: threeDS2Result },
                  })
                : JSON.stringify(threeDS2Result.data),
          },
        ],
      },
    });
    return data;
  };

  return {
    isZeroOrder,
    placeOrder,
    sendOrder,
    resetPlaceOrderButton,
    patchOrder,
    triggerPlaceOrder: async () => {
      switch (paymentMethod.value) {
        case 'KLARNA': {
          const klarnaPayload = await authorizeKlarnaPayment(
            cartStore.billingAddress,
            cartStore.isShippingAddressIncomplete()
              ? cartStore.billingAddress
              : cartStore.fullShippingAddress
          );
          if (!klarnaPayload) return;
          await requestOrder({
            paymentMethod: {
              additionalData: klarnaPayload,
            },
          });
          break;
        }
        case 'ZERO_ORDER':
        case 'GIFT_CARD':
        case 'REWARD_CARD':
        case 'REWARD_CODE':
        case 'LOYALTY_POINTS':
        case 'ATHLETES_PAYMENT': {
          // When paying full order with any payment instruments user is not prompted
          // for billing address but API still expects it hence we use shipping
          // address as a fallback
          cartStore.billingAddress = { ...cartStore.fullShippingAddress };
          await requestOrder({
            paymentMethod: {
              additionalData: {
                sessionID: orderStore.forterToken || cyberSourceStore.sessionID,
              },
            },
          });
          break;
        }
        // CREDIT_CARD
        default: {
          const { executeRecaptcha } = useReCaptcha(instance);
          const recaptchaToken = await executeRecaptcha('placeOrder');

          await requestOrder({
            recaptcha_response: recaptchaToken,
            paymentMethod: getCreditCardPayload(),
          });
        }
      }
    },
    requestOrder,
  };
};

const mapPlaceOrderErrorToCustomerNotifications = (errorDetails) =>
  errorDetails
    ?.flatMap((details) => details?.message)
    .filter((message) => message?.details?.previousShippingMethodId) ?? [];
