import { defineStore } from 'pinia';
import { StoredFeatureFlags } from '@vf/api-contract';

export const useFeatureFlagsStore = defineStore('featureFlags', {
  state: (): StoredFeatureFlags => ({
    allowApplePayOrderOnPDP: false,
    allowForterSessionToken: false,
    areEapiCircuitBreakersEnabled: false,
    areComingSoonProductsEnabled: false,
    areMonetateExperiencesSetInCmsForTemplatizedPages: false,
    areNarvarReturnsEnabled: false,
    areOnlineReturnsEnabled: false,
    areRewardCodesEnabled: false,
    cartProductRecommendationBeforeSummary: false,
    checkoutSimplifiedAddress: false,
    checkoutSkipReviewStep: false,
    checkoutStickyCtas: false,
    checkoutRegisteredUserSingleStep: false,
    configMinicartRemoveIcon: {
      enabled: false,
      hidden: true,
    },
    configureHealthCheckPageSettings: {
      categoryId: '',
      productId: '',
      searchQuery: '',
    },
    enable3ds: false,
    enableCloudinaryCdn: false,
    enableMegaMenuSSR: false,
    isApplePayEnabled: false,
    isBopisEnabled: false,
    isBopis20Enabled: false,
    isCheckoutRedesignEnabled: false,
    isCheckoutSuccessRedesignEnabled: false,
    isCoreRedesignEnabled: false,
    isCustomsOlapicNewWidget: false,
    isDynatraceRumEnabled: false,
    isFiltersUiUpdateEnabled: false,
    isFindInStorePageEnabled: false,
    isGoogleAutocompleteEnabled: false,
    isInternalPromotionTrackingEnabled: false,
    isMiniCartRedesignEnabled: false,
    isMiniCartRedesignSavingsEnabled: false,
    isMobileTokenEnabled: false,
    isNewColorDividerEnabled: false,
    isNotifyMeEnabled: false,
    isOlapicNewWidget: false,
    isOlapicNewWidgetTestingData: false,
    isPointsToCurrencyEnabled: false,
    isPricingStrikethroughEnabled: false,
    isProductReviewsPageEnabled: false,
    isProductSearchServiceCookieDriven: false,
    isPushMonetateExperienceDataToDynatraceEnabled: false,
    isQueueItEnabled: false,
    isQueueItWaitingRoomProxyEnabled: false,
    isSfccContactUs: false,
    isSimplifiedEnrollmentEnabled: false,
    isSmsPreferencesMyAccountEnabled: false,
    isSmsPreferencesOrderConfirmationEnabled: false,
    isSmsPreferencesSignUpEnabled: false,
    isStaticPdpEnabled: false,
    isStoresEapiEnabled: false,
    isVansPdpRedesignEnabled: false,
    isVansPlpRedesignEnabled: false,
    isWriteReviewPage: false,
    pdpColorChangeWithoutRefresh: false,
    pdpKlarnaTextAboveAddToCartCta: false,
    pdpProductRecommendationsAboveReviews: false,
    pdpShowApplePayAsPrimaryCta: false,
    pdpShowScarcityMessage: false,
    // Please add new flags in alphabetical order
  }),
});
