import { reactive } from '@nuxtjs/composition-api';
import { ComponentInstance } from '../types';
import {
  ROUTES,
  useAuthentication,
  useCart,
  useFavorites,
  useUrl,
  useGtm,
  useSearch,
  useSignInToStore,
} from '@vf/composables';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import debounce from '@vf/shared/src/utils/helpers/debounce';
import { Context } from '@vf/api-contract';
import { useHeaderAndFooterStore } from '../store/headerAndFooter';
import { storeToRefs } from 'pinia';
import { useUserStore } from '../store/user';

// 200 ms debounce for search suggestions
const SEARCH_SUGGESTIONS_DEBOUNCE_TIMEOUT = 200;

export default function useHeaderProps(
  root: ComponentInstance,
  { useModal, useLoader }
) {
  const router = root.$router;

  const headerAndFooterStore = useHeaderAndFooterStore(root.$pinia);
  const {
    totalItems,
    isMinicartOpen,
    setMiniCart,
    recentlyAddedProduct,
    deleteItem,
  } = useCart(root as any);

  const { localePath } = root;
  const {
    resetFiltersView,
    getSearchSuggestions,
    searchSuggestions,
    resetSearchSuggestions,
    setPdoSearch,
    setActivePage,
    isRedirected,
    redirectedUrl,
    getSearchResults,
    setQueryString,
  } = useSearch(root as any);
  const { favoritesCount, isFavoritesTooltipOpen } = useFavorites(root as any);
  const userStore = useUserStore(root);
  const { loggedIn } = storeToRefs(userStore);
  const { getRelativeUrl } = useUrl(root);
  const { dispatchEvent } = useGtm(root as any);
  const { clearStoreSession } = useSignInToStore(root as any);
  const { isProtectedPath, signOut } = useAuthentication(root);
  const { openModal, getModalSettingsFromLink } = useModal();
  const { showSpinner, hideSpinner } = useLoader();

  const recentlyAddedProductName = recentlyAddedProduct.value
    ? recentlyAddedProduct.value.name
    : '';

  const utilityNavigationItemsMapper = (item) => {
    if (item.role === 'favorites') {
      item = reactive({ ...item, counter: favoritesCount });
    }
    if (item.role === 'cart') {
      item = reactive({ ...item, counter: totalItems });
    }
    return item;
  };

  const trackMegaMenuExpand = (data) => {
    const categories = [data.l1];
    data.l2 && categories.push(data.l2);
    dispatchEvent(
      getEventFromTemplate('mega-menu:expand', {
        category: categories.join(' - '),
      })
    );
  };

  const getSearchSuggestionsDebounced = debounce(
    getSearchSuggestions,
    SEARCH_SUGGESTIONS_DEBOUNCE_TIMEOUT
  );

  const headerEventHandlers = {
    'close-minicart': () => {
      if (root.$viewport?.isSmall) {
        const className = 'toggle';
        const customsElement = document.querySelector('.customs-page');
        if (customsElement) {
          const customsElementClassList = customsElement.classList;
          customsElementClassList.remove(className);
          const headerHeight = document.querySelector('.cms-header')
            .clientHeight;
          const iframe = document.querySelector('.i-frame-container');
          iframe?.setAttribute(
            'style',
            `height: calc(100vh - ${headerHeight}px);`
          );
        }
      }
      setMiniCart(false);
    },
    'enter-search': async (q) => {
      setActivePage(1);
      setQueryString(q);
      resetFiltersView();
      await getSearchResults({ fromSearchForm: true });
      if (isRedirected.value) {
        router.push(localePath(redirectedUrl.value));
        return;
      }
      router.push(localePath(ROUTES.SEARCH(encodeURIComponent(q))));
    },
    'change-search': (value) => {
      const searchSuggestionParams = {
        query: value,
      };
      getSearchSuggestionsDebounced(searchSuggestionParams);
    },
    'close-suggestions': () => {
      root.$emit('closeSuggestions');
      resetSearchSuggestions();
    },
    'set-pdo-search': (value) => {
      setPdoSearch(value);
    },
    clicked: (value) => {
      router.push(value);
    },
    'nav-toggle-accordion': (data) => {
      if (data.opened) {
        trackMegaMenuExpand(data);
      }
    },
    'nav-active-megamenu-column': trackMegaMenuExpand,
    'nav-menu-link-clicked': (data) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Navigation',
          eventAction: 'Global Header Nav',
          eventLabel: `${data.l1}${data.l2 ? ` - ${data.l2}` : ''}${
            data.l3 ? ` - ${data.l3}` : ''
          }`,
        },
      });
      if (data.isModal) {
        openModal(getModalSettingsFromLink(data.link));
      }
    },
    'clear-session': (link) => {
      clearStoreSession(link);
    },
    'utility-nav-menu-link-clicked': async (data) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Navigation',
          eventAction: 'Global Utility Nav',
          eventLabel: data.title,
        },
      });
      if (data.isModal) {
        openModal({
          ...getModalSettingsFromLink(data.link),
          contextKey: Context.Modal,
        });
      }
      if (data.role === 'clearSession') {
        clearStoreSession(data.link);
      }
      if (data.role === 'chat') {
        dispatchEvent(
          getEventFromTemplate('live-chat:open', {
            eventLabel: root.$getEnvValueByCurrentLocale('LIVE_CHAT_VENDOR'),
            buttonLocation: 'utility-nav',
          })
        );
        return;
      }
      if (data.role === 'logout') {
        showSpinner();
        await signOut(undefined, !isProtectedPath(window.location.href));
        hideSpinner();
      }
    },
    'click:promobar-modal-link': (link: string) => {
      openModal({
        ...getModalSettingsFromLink(link),
        contextKey: Context.Modal,
      });
    },
    'close-favorites-tooltip': () => {
      isFavoritesTooltipOpen.value = false;
    },
    'set-mini-cart': (data) => {
      setMiniCart(data.state, data.delay);
    },
    'delete-item': async (product) => {
      await deleteItem(product.id);
      headerAndFooterStore.$patch({
        showRemoveItemNotificationInMinicart: true,
      });
      dispatchEvent({
        ...getEventFromTemplate('cart:remove', {}),
        overrideAttributes: {
          product,
          quantity: product.qty,
        },
      });
      dispatchEvent(getEventFromTemplate('cart:update', {}));
    },
    'reset-show-remove-notification': () => {
      headerAndFooterStore.$patch({
        showRemoveItemNotificationInMinicart: false,
      });
    },
  };

  function prepareProps(propsData: any) {
    return {
      ...propsData,
      logo: {
        ...propsData.logo,
        width: root.$themeConfig?.header?.logoWidth,
        height: root.$themeConfig?.header?.logoHeight,
      },
      utilityNavigation: {
        desktop: propsData.utilityNavigation.desktop.map(
          utilityNavigationItemsMapper
        ),
        mobile: propsData.utilityNavigation.mobile.map(
          utilityNavigationItemsMapper
        ),
      },
      mobileNav: {
        items: propsData.mobileNav.items.map(utilityNavigationItemsMapper),
        translations: propsData.mobileNav.translations,
      },
      isMinicartOpen: isMinicartOpen.value,
      isFavoritesTooltipOpen: isFavoritesTooltipOpen.value,
      favoritesListUrl: localePath(ROUTES.ACCOUNT('favourites-list')),
      createAccountUrl: localePath(ROUTES.ACCOUNT('create-account')),
      isCustomerLoggedIn: loggedIn.value,
      favoritesCount: favoritesCount.value,
      recentlyAddedProductName,
      searchSuggestions: searchSuggestions.value,
      getProductUrl: (url) =>
        getRelativeUrl(localePath(url?.toLowerCase().split('?')[0])),
      getSearchLink: (term) => localePath(ROUTES.SEARCH(term)),
      popupData: {
        cartLink: localePath(ROUTES.CART()),
        checkoutLink: localePath(ROUTES.CHECKOUT_SHIPPING()),
        isCartLinkDisabled: root.$route.path.endsWith(ROUTES.CART()),
        showRemoveItemNotification:
          headerAndFooterStore.showRemoveItemNotificationInMinicart,
      },
      favoritesTooltip: propsData.favoritesTooltip,
    };
  }

  return {
    prepareProps,
    headerEventHandlers,
  };
}
